@import url(https://fonts.googleapis.com/css?family=Roboto+Mono&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: "#e7e7e7";
  --text: "#212121";
  --primary: "#212121";
  --secondary: "#666";
  --primary-light: "#212121"; }

* {
  line-height: 1.5; }

html {
  background: "#e7e7e7";
  background: var(--bg); }

h2,
h3 {
  color: "#212121";
  color: var(--text); }

a {
  text-decoration: none !important;
  color: "#212121";
  color: var(--text); }

a:hover {
  border-bottom: solid 2px "#666";
  border-bottom: solid 2px var(--secondary);
  margin-bottom: -2px; }

.App {
  height: 100vh;
  width: 100vw;
  background: "#e7e7e7";
  background: var(--bg);
  overflow-x: hidden; }

.Content {
  padding: 20px;
  padding-top: 20px;
  box-sizing: border-box;
  max-width: 732px;
  height: 100%;
  margin: auto;
  background: "#e7e7e7";
  background: var(--bg);
  overflow-x: hidden; }

.Header {
  color: "#212121";
  color: var(--text); }

.Name {
  padding: 0;
  margin: 0; }

.Bio {
  color: "#212121";
  color: var(--text);
  font-size: 20px; }

.Contact {
  padding: 0;
  margin: 0; }

.TagContainer {
  margin: 0;
  padding: 0;
  margin-left: -6px;
  padding-bottom: 20px; }
  .TagContainer .Tag {
    background: "#e7e7e7";
    background: var(--bg);
    color: "#212121";
    color: var(--text);
    border: 1px solid "#212121";
    border: 1px solid var(--primary-light);
    font-size: 14px;
    padding: 0px 4px;
    border-radius: 4px;
    display: inline-block;
    margin: 6px;
    min-width: 50px;
    text-align: center;
    font-weight: normal; }

.Menu {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-items: center;
  justify-items: center;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  grid-gap: 20px;
  padding-top: 30px;
  padding-bottom: 20px !important; }
  .Menu .MenuIcon {
    width: 24px;
    height: 24px;
    fill: "#212121";
    fill: var(--text); }

.Menu {
  padding: 8px 8px 8px 0px; }
  .Menu a {
    color: "#212121";
    color: var(--text);
    font-size: 18px;
    text-decoration: none;
    font-weight: 500; }
  .Menu .Selected * {
    border-bottom: solid 2px "#212121" !important;
    border-bottom: solid 2px var(--primary) !important; }
  .Menu .Separator {
    display: inline; }

.Project {
  display: grid;
  grid-template-areas: "plang pname" ".     pdescription";
  grid-template-columns: 36px auto;
  grid-template-rows: 36px auto;
  padding-bottom: 20px; }
  .Project .ProjectName {
    color: "#212121";
    color: var(--text);
    font-size: 16px;
    font-weight: 600;
    grid-area: pname;
    align-self: center;
    padding-left: 12px; }
  .Project .ProjectDescription {
    color: "#212121";
    color: var(--text);
    font-size: 16px;
    grid-area: pdescription;
    padding-left: 12px; }
  .Project .ProjectLang {
    width: 36px;
    height: 36px;
    border-radius: 36px;
    font-size: 12px;
    font-weight: bold;
    color: "#212121";
    color: var(--primary);
    line-height: 36px;
    text-align: center;
    background: "#e7e7e7";
    background: var(--bg);
    border: 2px solid "#212121";
    border: 2px solid var(--primary-light);
    grid-area: plang;
    font-family: "Roboto Mono", monospace; }

.ThemeButton {
  fill: "#212121";
  fill: var(--text);
  float: right;
  width: 30px;
  height: 30px; }

/* Mobile Styles*/
@media all and (max-width: 768px) {
  .Container {
    padding: 10px; }
  .Projects .Project .ProjectName {
    font-size: 16px; }
  .Projects .Project .ProjectDescription {
    font-size: 14px; }
  .Projects .Project .ProjectLang {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    font-size: 8px;
    font-weight: bold;
    line-height: 24px;
    background: "#e7e7e7";
    background: var(--bg); }
  .Menu {
    padding: 8px 8px 8px 0px; }
    .Menu .MenuIcon {
      width: 20px;
      height: 20px; }
  .Contact {
    font-size: 12px; }
  .TagContainer .Tag {
    font-size: 12px;
    margin: 2px; }
  .Bio {
    font-size: 14px;
    padding-bottom: 8px; }
  h3 {
    font-size: 16px;
    padding: 0;
    margin: 0;
    padding: 20px 0 10px 0;
    color: "#212121" !important;
    color: var(--text) !important; }
  h2 {
    font-size: 18px;
    color: "#212121" !important;
    color: var(--text) !important; } }

